@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body{
  background-color: #fdfdfd;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
  
iframe {
  max-width: 100% !important;
  max-height: 200px !important;
  height: auto !important;
}

a{
  color: rgb(126, 84, 30);
}

ul{
  li{
    list-style: square inside;
    a{
      text-decoration: none;
    }
  }
}

ol{
  li{
    // list-style: none;
    // display: inline;
    // margin: 0 8px;
    a{
      text-decoration: none;
    }
  }
}


h3, .h3{
  font-size: 25px;
  text-decoration: none;
  font-weight: 400;
  color: black;
  a{
    text-decoration: none;
    font-weight: 400;
    color: black;
  }
}

h2{
  font-size: 25px;
}

h1{
  font-size: 25px;
}

.directcall{
  background-color: #06af47;
  margin: 5px 0;
  text-align: center;
  color: white;
  font-weight: bold;
  a{
    color: white;
  }
}

.info{
  a{
    text-decoration: none;
    &:hover{
      color: black;
    }
  }
  &__item{
    border: 1px solid slategray;
    padding: 10px;
    border-radius: 3px;
    &-title{
      text-align: center;
      
    }
    &-img{
      width: 100%;
    }
  }
}
.container{
  background-color: #EAE7DC;
  padding: 0 10px;
}

.title{
  display: block;
  padding: 10px 10px 0px 10px;
  text-align: center;
  margin:0;
  font-size: 5.0rem;
}

.menu{
  text-align: center;
  margin-top: 10px;
}

.menu-item{
  display: inline-block;
  padding: 3px 10px;
  margin: 3px 5px;
  border-radius: 3px;
  background-color: #e36f63;
  font-weight: 500;
}

.prime{
  background-color: #06af47;
  a{
    color: white;
    font-weight: bold;
  }
}

.menu-link{
  text-decoration: none;
  color: white;
  &:hover{
    color: white;
  }
}

.pricing{
  display: table;
}

.behandeling{
  border: 1.5px solid rgb(209, 205, 151); 
  padding: 5px;
  background-color: rgb(247, 243, 232);
  h3{
    text-align: center;
    &.thin{
      font-weight: 200;
    }
  }
  &-green{
    background-color: #9ed68c;
  }
}

.behandeling-item{
  display: table;
}

.post{
  .headerimage{
    height: 250px;
    width: auto;
    background-position: center;
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    margin: 0 0 15px 0;
  }
}

.product{
  border-radius: 3px;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 15px;
  &-item{
      border-radius: 3px;
      box-sizing: border-box;
      padding: 30px;
      @media only screen and (max-width: 600px) {
        padding: 0px;
      }
  }
  .details{
    background-color: #7e541e9d;
    border-radius: 4px;
    padding: 8px 16px;
    color: white;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;

    p{
      margin-bottom: 0;
    }
  }
}

.products{
  .prod{
    background-color: #7e541e9d;
    list-style: none;
    padding: 10px 20px;
    .title{
      font-size: 20px;
      margin: 8px 0;
      height: 2.5em;
    }
    .price{
      font-weight: bold;
      color: white;
      border: 1px solid #7e541e;
      display: inline-block;
      padding: 8px 16px;
      margin: 8px 0;
      background-color: #7e541ee3;
    }
    .details{
      font-weight: bold;
      color: white;
      font-size: 15px;
      border: 1px solid #7e541e;
      display: inline-block;
      padding: 4px 8px;
      margin: 4px 0;
      background-color: #7e541ee3;
    }
    .img{
      display: block;
      margin: auto;
      height: 200px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat no-repeat;
      background-position: center center;
    }
    .button{
      background-color: #eae7dc;
    }
  }
}
footer{
  background-color: rgb(223, 223, 223) !important;
  margin-top: 10px;

}

footer div{
  padding: 5px;
}

footer .contact p{
  margin: 5px;
}

.container .flexslider .slides img {
  height: 300px;
  -moz-user-select: none;
  object-fit: cover;

}

.container .flexslider{
  margin-bottom: 15px;
}

.news_item{
  padding: 10px;
  background-color: #7e541e94;
  border-radius: 3px;
  display: block;
  a{
    color: white;
    text-decoration: none;
    font-weight: bolder;
    font-size: 20px;
  }
}

h1.post-title{
    font-size: 30px;
}

.photo{
    max-width: 100%;
}

.button, #appointment_button{
    background-color: #7e541e;
    color: white;
    text-align: center;
  &-primair{
    margin-top: 20px;
  }
  &-secondair{
    margin-top: 0;
  }
  &#appointment_button{
    background-color: #06af47;
  }
  &:hover{
    color: white;
  }
}

.contact-form{
  border: 1px solid #7b7b7b;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 5rem;
  background-color: #c4bb98;
}

.submission-success{
  padding: 15px;
  background-color: #06af47;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.location-header{
  height: 20rem;
  background-size: cover;
  background-position: center;
  h1{
    text-align: center;
    padding: 5rem;
    box-sizing: border-box;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px #7b7b7b;
  }
}

.notification{
  background-color: rgb(255, 157, 101);
  a{
    color: white;
    background-color: #06af47;
    padding: 5px;
    text-decoration: none;
  }
}